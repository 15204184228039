import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Card = ({ article, langKey }) => {
  if (langKey === "tr") {
    return (
      <Link to={`/tr/article/${article.node.slug}`} className="uk-link-reset">
        <div className="uk-card uk-card-muted">
          <div className="uk-card-media-top">
            <Img
              fluid={article.node.image.childImageSharp.fluid}
            />
          </div>
          <div className="uk-card-body">
            <p id="category" className="uk-text-uppercase">
              {article.node.category.name_tr}
            </p>
            <p id="title" className="uk-text-large">
              {article.node.title_tr}
            </p>
          </div>
        </div>
      </Link>
    )
  } else {
    return (
      <Link to={`/article/${article.node.slug}`} className="uk-link-reset">
        <div className="uk-card uk-card-muted">
          <div className="uk-card-media-top">
            <Img
              fluid={article.node.image.childImageSharp.fluid}
            />
          </div>
          <div className="uk-card-body">
            <p id="category" className="uk-text-uppercase">
              {article.node.category.name}
            </p>
            <p id="title" className="uk-text-large">
              {article.node.title}
            </p>
          </div>
        </div>
      </Link>
    )
  }
}

export default Card