import React, { Fragment } from "react"
import Card from "./card"

const articlesRenderer = (langKey, leftArticles, rightArticles) => {
  return (
    <div className="uk-child-width-1-2" data-uk-grid>
      <div>
        {leftArticles.map((article, i) => {
          return (
            <Card langKey={langKey} article={article} key={`${article.node.slug}${langKey !== 'en' ? `-${langKey}` : ''}`} />
          )
        })}
      </div>
      <div>
        <div className="uk-child-width-1-2@m uk-grid-match" data-uk-grid>
          {rightArticles.map((article, i) => {
            return (
              <Card langKey={langKey} article={article} key={`${article.node.slug}${langKey !== 'en' ? `-${langKey}` : ''}`} />
            )
          })}
        </div>
      </div>
    </div>
  );
}

const articlesMin = (langKey, articles) => {
  return(
    <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-text-center" data-uk-grid>
      {articles.map((article, i) => {
        return (
          <div key={`${article.node.slug}${langKey !== 'en' ? `-${langKey}` : ''}`}>
            <div className="uk-padding uk-box-shadow-large" >
              <Card langKey={langKey} article={article} />
            </div>
          </div>
        )
      })}
    </div>
  );
}

const Articles = ({ articles, langKey }) => {

  const leftArticlesCount = Math.ceil(articles.length / 5)
  const leftArticles = articles.slice(0, leftArticlesCount)
  const rightArticles = articles.slice(leftArticlesCount, articles.length)
  
  return (
    <Fragment>
      <div className="uk-visible@l">
          {articlesRenderer(langKey, leftArticles, rightArticles)}
      </div>
      <div className="uk-hidden@l">
          {articlesMin(langKey, articles)}
      </div>
    </Fragment>
  )
}

export default Articles